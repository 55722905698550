import React, { useState } from 'react';
import '../css/Login.css';
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../css/AdminForgotPassword.css';

const AdminForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    alert('Check your email for the reset link!');
    setEmail(''); // Clears the email field after submission
  };

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h2>Forgot Password  &nbsp;<FontAwesomeIcon icon={faUser} className="my-custom-class" id='icon-admin' />
          </h2>
          <form onSubmit={handleSubmit}>
            <label><b>Email </b></label>
            <input 
              type="email" 
              placeholder="Enter your email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <button type="submit" className="btn-secondary m-3">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminForgotPassword;
