import React from 'react';
import '../css/page_header.css';
import backgroundImage from '../assets/about-bg1.jpg'; // Import the image

export default function PageHeader({ title, path, name }) {
    return (
        <div className="page-header" style={{ backgroundImage: `linear-gradient(rgba(0, 37, 84, 0.8), rgba(0, 37, 84, 0.8)), url(${backgroundImage})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className='m-top'>{title}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
