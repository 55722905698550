import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/events.css'; // Create and import your custom CSS for styling
import event1 from '../assets/Images/tree-plantation_img_eve2.png';
import event2 from '../assets/Images/fund_raising_eve4.jpg';
import event3 from '../assets/Images/school_bag_camp.jpeg';
import event4 from '../assets/Images/img_world_ngo_ev1.webp';
import event5 from '../assets/Images/eve3_world_environment.avif';
import event6 from '../assets/Images/blood_donation_eve5.jpg';
import liveevent1 from '../assets/Images/img_event_night_dress_resized_2.jpg'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const eventsData = [
    {
        title: "Tree Plantation",
        date: "October 20, 2024",
        description: "We at Sant Sevalal Maharaj Foundation have an aim of planting 1000 trees in an year and you as an individual can come,join us. One Tree One Life ",
        imageUrl: event1
    },
    {
        title: "Fund Raising",
        date: "November 10, 2024",
        description: "To provide the children and the needy the best we can we need funds to manage and we know that you as an individual can contribute towards it.",
        imageUrl: event2
    },
    {
        title: "School Bag Donation Camp",
        date: "September 30, 2024",
        description: "Join us for our upcoming School Bag Donation Camp, where we'll provide essential school supplies to underprivileged students.",
        imageUrl: event3
    }
];

const moreEventsData = [
    {
        title: "World NGO Day",    
        date: "February 27, 2024",
        description: "Celebrate World NGO Day with us as we honor the incredible work of non-governmental organizations globally. Join us in recognizing their contributions to society, sharing success stories, and fostering collaboration for a better world. Together, we can amplify the impact of NGOs and drive positive change.",
        imageUrl: event4
    },
    {
        title: "World Environment Day",
        date: "June 5, 2024",
        description: "Join us in celebrating World Environment Day as we come together to protect and preserve our planet. Participate in impactful activities, learn about sustainable practices, and be part of the global movement for environmental conservation. Together, let's create a greener, healthier future for all. Happy World Environment Day",
        imageUrl: event5
    },
    {
        title: "Night Dress Distribution",
        date: "September 14, 2024",
        description: "The Sant Sewalal Maharaj Foundation recently distributed night dresses to needy students in a rural school hostel as part of their ongoing efforts to support underprivileged children. The initiative aimed to provide comfort and dignity to students, ensuring they have appropriate clothing for their daily routines.",
        imageUrl: liveevent1
    }
];

export default function Events() {
    return (
        <>
        <Navbar/>
        <div className="events-container">
            <h1 className="text-center my-5" id='ctext'>Upcoming Events</h1>
            <div className="row">
                {eventsData.map((event, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <div className="card event-card">
                            <img src={event.imageUrl} className="card-img-top" alt={event.title} />
                            <div className="card-body">
                                <h5 className="card-title">{event.title}</h5>
                                <p className="card-text">{event.date}</p>
                                <p className="card-text">{event.description}</p>
                                <a href="DonateUs" className="btn btn-primary">Donate Now</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <h2 className="text-center my-5">Past Events</h2>
            <div className="more-events">
                {moreEventsData.map((event, index) => (
                    <div className="more-event-card" key={index}>
                        <img src={event.imageUrl} alt={event.title} className="more-event-img" />
                        <div className="more-event-content">
                            <h3 className="more-event-title">{event.title}</h3>
                            <p className="more-event-date">{event.date}</p>
                            <p className="more-event-description">{event.description}</p>
                            <a href="DonateUs" className="btn btn-secondary">Donate Now</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="footer-class"><Footer/></div>
        
        </>
    );
}
