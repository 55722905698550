import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Login.css';
import Navbar from './Navbar';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, { email, password });
      if (response.status === 200) {
        alert('Login Successfully!');
        console.log('Login Successfully');
        navigate('/'); // Redirect to User dashboard or User home page
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Invalid Email or Password!');
        console.log('Invalid Email or Password!');
      } else {
        alert('Login Error!');
        console.log('Login Error..', error);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-page">
      {/* <div className="right-section">
          {/* The background image will be set via CSS */}
        {/* </div>  */}
        <div className="left-section">
          <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <label><b>Email</b></label>
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <label><b>Password</b></label>
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              <button type="submit" className="btn-secondary">Login</button>
              <Link to="/forgot-password" className="forgot-password">Forgot Password?</Link>
            </form>
            <p>Don't have an account? <Link className='signupbtn' to="/signup">Sign Up</Link></p>
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Login;
